import React from 'react'
import styled from '@emotion/styled'
import Button from 'library/atoms/Button'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { LEGACY_EOLA_ROUTES, EOLA_ROUTES } from 'enums/routes'
import Icon from 'shared/library/atoms/Icon'

const EolaHeader = () => {
  const { t } = useTranslation('common')
  return (
    <StyledHeader>
      <Link href={EOLA_ROUTES.HOME.url()} passHref legacyBehavior>
        <StyledLink>
          <Icon name="logo" color="darkGrey" height={26} width={70} />
        </StyledLink>
      </Link>
      <StyledNav>
        <Link href={EOLA_ROUTES.MARKETPLACE.url()} passHref legacyBehavior>
          <StyledLink desktopOnly>{t('book-experiences')}</StyledLink>
        </Link>
        <StyledLink desktopOnly href={LEGACY_EOLA_ROUTES.BUSINESS_HOME.url()}>
          {t('eola-for-business')}
        </StyledLink>
        <StyledLink href={LEGACY_EOLA_ROUTES.BLOG.url()}>
          {t('blog')}
        </StyledLink>
        <StyledButton
          as="a"
          href={LEGACY_EOLA_ROUTES.ENTHUSIASTS_SIGN_IN.url()}
          variant="eola-primary"
        >
          {t('sign-in')}
        </StyledButton>
      </StyledNav>
    </StyledHeader>
  )
}

export default EolaHeader

const StyledHeader = styled.header(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
  padding: theme.mixins.pxSpread([0, theme.space[5]]),
  marginBottom: theme.space[3],
  [theme.mediaQueries.md]: {
    marginBottom: theme.space[4],
    padding: 0
  }
}))

const StyledNav = styled.nav(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.space[3]
}))

const StyledLink = styled.a<{
  desktopOnly?: boolean
}>(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.colors.text.secondary,
    padding: theme.mixins.pxSpread([theme.space[2], theme.space[3]]),
    ...theme.fontStyle.h6,
    ...theme.mixins.eola.link,
    '&:hover': {
      color: theme.colors.black
    }
  }),
  ({ desktopOnly, theme }) =>
    desktopOnly
      ? {
          display: 'none',
          [theme.mediaQueries.md]: {
            display: 'inline-block'
          }
        }
      : undefined
)

// Special case for header
const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: theme.fontSizes.sm,
  lineHeight: 1,
  padding: theme.mixins.pxSpread([theme.space[3], theme.space[5]])
}))
