// This function can be used to construct a string of query params from an object.
// Will filter out records with no values and join the rest with '&'.

const createQueryParams = (queryParamsObject: {
  [key: string]: string | undefined
}) => {
  const queryKeys = Object.entries(queryParamsObject)
    .filter(([_key, value]) => value !== undefined)
    .reduce((obj: any, [key, value]) => {
      obj[key] = value
      return obj
    }, {})

  if (Object.keys(queryKeys).length === 0) {
    return ''
  }

  if (queryKeys) {
    return new URLSearchParams(queryKeys).toString()
  }
}

export default createQueryParams
